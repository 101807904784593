import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { SchedulesResponse } from "../interfaces/agendamento";
import { AgendamentoInternoReturn } from "../interfaces/agendamentoInterno";
import { apisiseci } from "./api";


export const getAgendamentos = (
  token: string,
  params?: {

    hoje?: boolean;
    anteriores?: boolean;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu";
    in_status?: string;
    out_status?: string;
    page?: number;
    unidade?: string;
    guiche_id?: string;
  }
) =>
  apisiseci.get<SchedulesResponse>("agendamento/agendamentos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      ...params,
    },
  });

export const updateStatusAgendamento = (
  token: string,
  id: number,
  status:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Não Compareceu"
    | "Compareceu",
  guiche: string

): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/agendamentos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addObservation = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `agendamento/observacao/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


export const checarHorariosInternos = (
  date: Date,
  unity: string,
  serviceID: number
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, "yyyy-MM-dd");
  return apisiseci.get(
    `agendamento/checar_horarios_interno/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`
  );
};

export const agendarInterno = (
  token: string,
  cpf: string,
  userId: number,
  date: Date,
  unity: string,
  serviceID: number,
  hour: string
) => {
  return apisiseci.post<AgendamentoInternoReturn>(
    `agendamento/agendar_interno/`,
    {
      cpf,
      user: userId,
      unidade: unity,
      servico: serviceID,
      data: format(date, "yyyy-MM-dd"),
      hora: hour,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const searchAgendamentos = (
  token: string,
  params?: {
    data?: string;
    cpf?: string;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu";
    page?: number;
    guiche_id?: string;
  }
) =>
  apisiseci.get<SchedulesResponse>("agendamento/consulta_presenciais/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const reagendar = ({
  token,
  data,
  ticket,
  hora,
  unidade,
  guiche_id,
}: {
  token: string,
  data: Date,
  ticket: string,
  hora: string,
  unidade: string,
  guiche_id: string,
}) =>
  apisiseci.post("agendamento/fazer/reagendamento/", {
    ticket,
    data: format(data, "yyyy-MM-dd"),
    hora,
    unidade,
  },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        guiche_id,
      }
    });