import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { apisiseci } from "./api";

export const getAtendimentos = (
  token: string,
  params?: {
    hoje?: boolean;
    anteriores?: boolean;
    page?: number;
    data?: string;
    status?:
    | "Aguardando"
    | "Em Atendimento"
    | "Pendente"
    | "Finalizado"
    | "Compareceu"
    | "Cancelado";
    guiche_id: string;
  },
): Promise<AxiosResponse> =>
  apisiseci.get("atendimento/atendimentos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const updateStatusAtendimento = (
  token: string,
  id: string,
  status:
    | "Aguardando"
    | "Em Atendimento"
    | "Pendente"
    | "Finalizado"
    | "Cancelado"
    | "Compareceu"
    | "Não Compareceu",
  guiche: string
): Promise<AxiosResponse> =>
  apisiseci.put(
    `atendimento/atendimentos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const getAtendimento = (
  token: string,
  id: string,
  guiche_id: string,
): Promise<AxiosResponse> =>
  apisiseci.get(`atendimento/atendimentos/${id}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      guiche_id,
    },
  });

  export const checarIntervaloAgendamentos = (
  token: string,
  servico: string,
  tipo: string,
  unidade: string,
  data_inicio: string,
  data_fim: string,
): Promise<AxiosResponse> => apisiseci.get('agendamento/checar_intervalo', {
  params: {
    servico,
    tipo,
    unidade,
    data_inicio,
    data_fim,
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const checarDisponibilidadeAgendamentos = (token: string, id: string, type?: string): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/checar_disponibilidade_unidade/?servico=${id}&tipo=${type}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const atenderOnline = (
  token: string,
  servico: number,
  unidade: string,
  titulo: string,
  conteudo: string,
  tipo: string,
  date: Date,
  hora: string,
  topico?: string,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.put(`atendimento/solicitar/${topico}/`, {
    servico,
    unidade,
    titulo,
    conteudo,
    data,
    tipo,
    hora,
    status:"Aguardando",
    para_dependente:false
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reagendarAtendimento = (
  token: string,
  agendamento_online_id: string,
  unidade: string,
  date: Date,
  hora: string,
  tipo: string,
  ticket?: string,
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  const dataToSend: any = {
    agendamento_online_id,
    data,
    hora,
    unidade,
    tipo,
    para_dependente: false
  };

  if (tipo === 'presencial') {
    dataToSend.ticket = ticket;
  }

  return apisiseci.post('agendamento/fazer/reagendamento-cidadao/', dataToSend, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};



export const checarHorariosAtendimento = (
  date: Date,
  unity: string,
  serviceID: number,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci
    .get(`atendimento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`);
};

export const sendReply = (
  token: string,
  id: string,
  message: string,
  haveAnexo?: boolean
): Promise<AxiosResponse> =>
  apisiseci.post(
    "atendimento/responder/",
    {
      topico: id,
      conteudo: message,
      anexo: haveAnexo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );


export const attachFilesOnReply = (
  token: string,
  titulo: string,
  descricao: string,
  arquivo: File,
  resposta: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("titulo", titulo);
  formData.append("descricao", descricao);
  formData.append("arquivo", arquivo);
  formData.append("resposta", resposta);
  return apisiseci.post("atendimento/anexar_resposta/", formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const markAsRead = (token: string, replayId: number): Promise<AxiosResponse> =>
  apisiseci.put(
    `atendimento/marcar_resposta/${replayId}/`,
    {
      lido: true,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addObservationAttendence = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `atendimento/observacao/`,
    {
      topico: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );