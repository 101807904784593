import { AxiosResponse } from "axios";
import { format } from "date-fns";

import { SchedulesOrganicsResponse } from "../interfaces/agendamento";
import { apisiseci, apisso } from "./api";

export const getAgendamentosDiarios = (
  token: string,
  params?: {
    hoje?: boolean;
    anteriores?: boolean;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu";
    in_status?: string;
    out_status?: string;
    page?: number;
    unidade?: string;
    guiche_id?: string;
  }
) =>
  apisiseci.get<SchedulesOrganicsResponse>("diario/atendimentos/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const updateStatusAgendamentoDiario = (
  token: string,
  id: number,
  status:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu",
  guiche: string
): Promise<AxiosResponse> =>
  apisiseci.put(
    `diario/atendimentos/${id}/?guiche_id=${guiche}`,
    {
      id,
      status,
      guiche,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const addObservationAgendamentoDiario = (
  token: string,
  id: string,
  observation: string
): Promise<AxiosResponse> =>
  apisiseci.post(
    `diario/observacao/`,
    {
      agendamento: id,
      conteudo: observation,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const searchAgendamentosDiarios = (
  token: string,
  params?: {
    data?: string;
    cpf?: string;
    status?:
    | "Cancelado"
    | "Finalizado"
    | "Em Atendimento"
    | "Aguardando"
    | "Compareceu"
    | "Não Compareceu";
    page?: number;
  }
) =>
  apisiseci.get<SchedulesOrganicsResponse>("diario/consulta/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const gerarAtendimentoOrganico = (
  token: string,
  nome: string,
  cpf: string,
  servico: number,
  setor: number,
  unidade: string,
) => apisiseci.post('diario/agendar/', {
  nome,
  cpf,
  servico,
  setor,
  unidade,
  tipo: "Portal",
  para_dependente: false,
}, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const agendamentoOrganico = (
  token: string,
  nome: string,
  cpf: string,
  servico: number,
  setor: number,
  tipo: string,
  unidade_slug: string,
  para_dependente: boolean,
  date?: Date,
  hora?: string,

) => {
  const dateScheduler = date && format(date, 'yyyy-MM-dd');


  return apisiseci.post('/diario/agendar/', {
    nome,
    cpf,
    servico,
    setor,
    tipo,
    unidade: unidade_slug,
    para_dependente,
    data: dateScheduler,
    hora,
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

}


export const checarHorarios = (token: string, params: {
  servico: number;
  unidade: string;
  data?: Date;
}
) => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const dateFormatted = params.data && format(params.data, 'yyyy-MM-dd');
  return apisiseci.get(`/diario/checar_horarios/`, {
    headers: {
      Authorization: `Bearer ${token}`
    }, params: { ...params, data: params.data ? dateFormatted : currentDate }
  })
}

export const checarHorariosWithDate = (
  date: Date,
  unity: string,
  serviceID: number,
  token: string,
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci
    .get(`agendamento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    );
};


type checharCPFProps = {
  cpf: string,
  token: string,
}


export const checarCpf = ({ cpf, token }: checharCPFProps): Promise<AxiosResponse> => {
  return apisso.post(`/admin/checar/atendente/cpf/`, { cpf }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};
