import React, { useEffect, useMemo } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import QrCode from 'qrcode.react';
import { useStyles } from "./style";
import { useLocation } from 'react-router-dom';

import { cpfMask } from '../../utils/cpfFormat';
import dateFormat, { extractHourFormat } from '../../utils/dateFormat';
import { MdClose, MdOutlinePrint } from 'react-icons/md';

export default function PrintScheduler() {
  const classes = useStyles();
  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  useEffect(() => {
    window.print();
  }, []);

  function handleDateFormat(date: string) {
    return Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    }).format(new Date(date));
  }

  return (
    <Box className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.print()}
        className={classes.buttons}
      >
        <MdOutlinePrint size={20} style={{ marginRight: 5 }} />
        Imprimir
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => window.close()}
        className={classes.buttons}
      >
        <MdClose size={20} style={{ marginRight: 5 }} />
        Sair
      </Button>
      <Box className={classes.containerQrCode}>
        <Box>
          <QrCode
            value={query.get('cod')}
          />
        </Box>
        <Typography className={classes.codText}>
          {query.get('cod')}
        </Typography>
      </Box>

      <Box className={classes.infoFieldset}>
        <Typography className={classes.title}>Nome</Typography>
        <Typography className={classes.subTitle}>
          {query.get('nome')}
        </Typography>
        <Typography className={classes.title}>CPF</Typography>
        <Typography className={classes.subTitle}>
          {cpfMask(query.get('cpf'))}
        </Typography>
        <Typography className={classes.title}>Data/Hora</Typography>
        <Typography className={classes.subTitle}>{query.get('data') && dateFormat(query.get('data'))} - {query.get('hora')}</Typography>
        <Typography className={classes.title}>Local de Atendimento</Typography>
        <Typography className={classes.subTitle}>{JSON.parse(localStorage.getItem('gov_ticket_office') || '{}')?.unidade?.nome}</Typography>
        <Typography className={classes.title}>Protocolo</Typography>
        <Typography className={classes.subTitle}>
          {query.get('protocolo') || ''}
        </Typography>
      </Box>
      <Box className={classes.dateInfoFieldset}>
        <Typography className={classes.dateInfoText}>
          {`Emitida às ${extractHourFormat(query.get('emissao'))} do dia ${handleDateFormat(query.get('emissao'))}`}
        </Typography>
      </Box>
    </Box>
  )
}