import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    TextField,
    Typography,
} from "@material-ui/core";
import { CheckCircleOutline, Close, PersonRounded } from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AgendamentoDiario } from "../../../../interfaces/agendamento";
import { addObservationAgendamentoDiario, updateStatusAgendamentoDiario } from "../../../../services/agendamentoOrganico";
import { cpfMask } from "../../../../utils/cpfFormat";
import { dateFormat } from "../../../../utils/dateFormat";

import useStyles from "./style";

interface Props {
  openAttendanceCancel: boolean;
  setOpenAttendanceCancel: (openAttendanceCancel: boolean) => void;
  schedule: undefined | AgendamentoDiario;
}

export default function AttendanceDialogCancel({
  setOpenAttendanceCancel,
  openAttendanceCancel,
  schedule,
}: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [observation, setObservation] = useState(undefined);
  const [isOk, setIsOk] = useState<boolean>(false);
  const history = useHistory();

  const handleClose = () => {
    if (isOk) {
      return history.go(0);
    }
    setOpenAttendanceCancel(false);
  };

  const handleFinishAttendance = async (scheduleId) => {
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      const guiche = JSON.parse(localStorage.getItem("gov_ticket_office") || "").id;

      setLoading(true);
      if (token) {
        const { status } = await updateStatusAgendamentoDiario(
          token,
          scheduleId,
          "Não Compareceu",
          guiche,
        );
        if (status === 200 && !!observation) {
          await addObservationAgendamentoDiario(token, scheduleId, observation || "");
        }
        setIsOk(true);
      }
    } catch (error) {
      console.log("Erro", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      open={openAttendanceCancel}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {isOk ? (
        <DialogTitle className={classes.header}>
          Atendimento finalizado com sucesso!
        </DialogTitle>
      ) : (
        <DialogTitle className={classes.header}>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
          <Typography className={classes.textHeaderModal}>
            Deseja informar o não comparecimento do cidadão?
          </Typography>
        </DialogTitle>
      )}

      {isOk ? (
        <>
          <DialogContent>
            <CheckCircleOutline
              style={{
                fontSize: 60,
                color: "#004F9F",
                textAlign: "center",
                width: "100%",
              }}
            />
            {schedule && (
              <>
                <Box style={{ textAlign: "center" }}>
                  <Box className={classes.titleDependents}>
                    <Typography className={classes.textModalTitleUDependent}>
                      {schedule.nome}
                    </Typography>
                    <Typography className={classes.textModalTitleUDependentCPF}>
                      {cpfMask(schedule.cpf)}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={3} style={{ textAlign: "center" }}>
                  <Typography className={classes.textModalTitleServico}>
                    serviço
                  </Typography>
                  <Typography className={classes.textModalServico}>
                    {schedule.servico.titulo}
                  </Typography>
                </Box>
                <Box mb={3} style={{ textAlign: "center" }}>
                  <Typography className={classes.textModalTitleServico}>
                    Status
                  </Typography>
                  <Typography className={classes.textModalServico}>
                    Não compareceu
                  </Typography>
                </Box>
              </>
            )}
            <Divider light />
            <Button
              style={{
                color: "#FFF",
                fontSize: 14,
                float: "right",
                marginTop: 20,
              }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClose}
            >
              FECHAR
            </Button>
          </DialogContent>
        </>
      ) : (
        <>
          {schedule && (
            <DialogContent>
              <PersonRounded fontSize="large" color="primary" />
              <Box className={classes.titleDependents}>
                <Typography className={classes.textModalTitleUDependent}>
                  {schedule.nome}
                </Typography>
                <Typography className={classes.textModalTitleUDependentCPF}>
                  {cpfMask(schedule.cpf)}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  serviço
                </Typography>
                <Typography className={classes.textModal}>
                  {schedule.servico.titulo}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  data/hora
                </Typography>
                <Typography className={classes.textModal}>
                  {`${dateFormat(schedule.data)} às ${schedule.hora}`}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  status
                </Typography>
                <Typography className={classes.textModal}>
                  {schedule.status}
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography className={classes.textModalTitle}>
                  observações:
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={5}
                  variant="outlined"
                  margin="normal"
                  disabled={loading}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      fontFamily: "Mulish",
                      fontWeight: 400,
                    },
                  }}
                  style={{ width: "100%", marginBottom: 10 }}
                  value={observation}
                  onChange={(e) => {
                    setObservation(e.target.value);
                  }}
                />
              </Box>
              <Divider light />
              <div className={classes.buttonCloseModal}>
                {!loading && (
                  <Link className={classes.link} onClick={handleClose}>
                    fechar
                  </Link>
                )}
                <Button
                  style={{ color: "#FFF", fontSize: 12, flex: 0.5 }}
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => handleFinishAttendance(schedule.id)}
                  disabled={loading}
                >
                  {!loading ? "CONFIRMAR" : <CircularProgress />}
                </Button>
              </div>
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
}
