import React, { useEffect, useState, memo, useMemo } from "react";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Link,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Box,
  DialogActions,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHistory, Link as LinkR } from "react-router-dom";
import { Service } from "../..";
import { agendamentoOrganico, checarCpf, checarHorarios } from "../../../../services/agendamentoOrganico";
import { cpfMask, removeCpfMask } from "../../../../utils/cpfFormat";

import { useStyles } from "./style";
import { MdCheckCircleOutline } from "react-icons/md";
import toastMessage from "../../../../utils/handleToastMessage";
import AsyncSelect from "../../../../Components/AsyncSelect";
import { getService, getServiceWithParams } from "../../../../services/servicos";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";

interface ServiceProps {
  id: number;
  setor: number;
  slug: string;
  titulo: string;
}
interface Props {
  openSchedulerDialog: boolean;
  setOpenSchedulerDialog: (openSchedulerDialog: boolean) => void;
  service_data: Service;
  unidade_slug: string;
}

interface SchedulerProps {
  id: number,
  cpf: string,
  servico: number,
  status: string,
  data_solicitacao: string,
  unidade: string,
  ticket: string,
  data: string,
  prioridade: boolean,
  protocolo?: string,
  protocolo_atendimento?: string;
  user: null,
  hora: string,
  setor: {
    id: number;
    nome: string;
    sigla: string;
  },
  guiche: {
    id: number;
    ordem: number;
    tipo: string;
  },
  tipo: string,
  hora_inicio: string,
  para_dependente: boolean,
  nome: string,
  updated_at: string
}

function DialogNotAttend({
  setOpenSchedulerDialog,
  openSchedulerDialog,
  service_data,
  unidade_slug
}: Props): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [nome, setNome] = useState<string>('');
  const [nomeValidationMessage, setNomeValidationMessage] = useState<string | undefined>();
  const [cpf, setCpf] = useState<string>('');
  const [cpfValidationMessage, setCpfValidationMessage] = useState<string | undefined>();
  const [serviceSelected, setServiceSelected] = useState<ServiceProps>();
  const [IsSavedScheduler, setIsSavedScheduler] = useState(false);
  const [schedulerData, setSchedulerData] = useState<SchedulerProps>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [loadingService, setLoadingService] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [isFutureDate, setIsFutureDate] = useState<boolean>(false);
  const [searchService, setSearchService] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [unitySelected, setUnitySelected] = useState<string | undefined>();
  const [validateQueryService, setValidateQueryService] = useState<boolean>(true);
  const [hoursList, setHoursList] = useState<string[]>([]);
  const [hourSelected, setHourSelected] = useState<string | undefined>();

  const dateTomorrow = useMemo(() => {
    if (isFutureDate) {
      let todayDate = new Date();
      return todayDate.setDate(todayDate.getDate() + 1);
    }
    return new Date();
  }, [isFutureDate]);


  const classes = useStyles();
  const history = useHistory();

  const handleCheckHours = async (serviceID: number, unity: string, date?: Date) => {
    setLoadingService(true);
    setHoursList([]);
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      const { data } = await checarHorarios(
        token,
        {
          servico: serviceID,
          unidade: unity,
          data: date,
        }
      );
      if (data.horarios) {
        setHoursList(data.horarios);

        setErrorMessage(undefined);
      }
    } catch (error: any) {
      console.log(error);

      if (error?.response?.data) {

        setErrorMessage(error.response.data?.message);
      }
    } finally {
      setLoadingService(false);
    }
  }


  const handleClose = () => {
    !loading && setOpenSchedulerDialog(false);
  };

  const handleSchedulerOrganic = async () => {
    let cpfWithoutMask = removeCpfMask(cpf);

    if (nome && nome.length < 6) {
      setNomeValidationMessage('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (!cpfWithoutMask) {
      setCpfValidationMessage('Informe o CPF.');
      return
    };

    if (cpfWithoutMask.length < 11) {
      setCpfValidationMessage('CPF inválido.');
      return;
    }

    try {
      setLoading(true);
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );

      const serviceID = isFutureDate ? searchService?.data?.id : serviceSelected?.id;
      const serviceSetor = isFutureDate ? searchService?.data?.setor_id : serviceSelected?.setor;
      const unity = isFutureDate ? unitySelected : unidade_slug;


      const { data, status } = await agendamentoOrganico(
        token || '',
        nome,
        cpfWithoutMask,
        serviceID as number,
        serviceSetor as number,
        "Unidade",
        unity,
        false,
        isFutureDate ? selectedDate : undefined,
        hourSelected ?? undefined,

      );

      if (status === 201) {
        toastMessage({
          type: "success",
          message: "Agendamento realizado com sucesso!",
        });
        setSchedulerData(data);
        setIsSavedScheduler(true);
      }
    } catch (err: any) {
      if (err?.response?.status === 406) {
        setErrorAlert(true);
      }
      toastMessage({
        type: "error",
        message: err?.response?.data?.message || "Falha ao realizar agendamento!",
      });
    } finally {
      setLoading(false);
    }
  };

  const itemFormatted = (value: any) => {
    return {
      value: value?.slug,
      label: value?.titulo,
      data: value || {},
    }
  }

  const handleSetService = async (value: any) => {
    try {
      setLoading(true)
      if (!!value?.data?.slug) {
        const { data } = await getService(value?.data?.slug);
        if (!!data?.results?.length) {
          setSearchService(itemFormatted(data?.results[0] || {}));
        }
      }
      setErrorMessage(undefined);
      setUnitySelected(undefined);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  };
  const redirectToPrint = () => {
    let setor_name = '';
    if (schedulerData?.setor?.nome) {
      setor_name = schedulerData?.setor?.nome;
    } else {
      setor_name = 'Geral';
    }
    setTimeout(() => {
      window.open(`
      /imprimir_agendamento/?cod=${schedulerData?.ticket}&nome=${schedulerData?.nome}&cpf=${schedulerData?.cpf}&data=${schedulerData?.data}&hora=${schedulerData?.hora}&emissao=${schedulerData?.updated_at}&setor=${setor_name}&guiche_ord=${schedulerData?.guiche?.ordem}&guiche_tipo=${schedulerData?.guiche?.tipo}&protocolo=${schedulerData?.protocolo}`, '__blank');
    }, 900);
  }

  const checkCpf = async (cpf: string) => {
    try {
      const token: string | null = localStorage.getItem(
        "gov_access_token_sso"
      );
      setLoading(true);
      if (cpf.length === 14) {
        const { data }: any = await checarCpf({
          cpf: removeCpfMask(cpf),
          token: token || "",
        });
        if (data?.nome) {
          setNome(data.nome);
        }
      }
    } catch (error: any) {
      toastMessage({
        type: "error",
        message: error?.response?.data?.details || "Erro ao buscar CPF"
      });
      setNome('');
      setCpfValidationMessage('Cpf não encontrado');
    } finally {
      setLoading(false);
    }
  }


  const listFormatted = (values: any[]) => {
    return values?.map((serviceData: any) => (itemFormatted(serviceData)));
  }

  const handleSearchService = async (search: string) => {

    setValidateQueryService(false);
    const tokenSiseci: string | null = localStorage.getItem(
      'gov_access_token_sso',
    );
    if (search.length > 2 && tokenSiseci) {
      setValidateQueryService(true);
      const { data } = await getServiceWithParams(
        {
          ativo: true,
          page: 1,
          publico_especifico: '',
          search,
          agendavel: true,
        },
        tokenSiseci,
      );
      const responseFormatted = listFormatted(data?.results || [])
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return responseFormatted;
    }
  };

  useEffect(() => {
    if (openSchedulerDialog && schedulerData) {
      redirectToPrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSchedulerDialog, schedulerData]);

  return (
    <Dialog
      fullWidth
      open={openSchedulerDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: IsSavedScheduler ? 'none' : 'initial' }}>
        <Box className={classes.headerModal}>
          <Typography className={classes.titleModal}>
            Gerar Atendimento
          </Typography>
          <IconButton onClick={handleClose} className={classes.buttonClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Box className={classes.boxRowPriority}>
            <Box>
              <Checkbox
                checked={isFutureDate}
                onChange={() => setIsFutureDate((oldState) => !oldState)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box>
              <Typography className={classes.labelCheckbox}>
                Você deseja agendar este atendimento para uma data futura?
              </Typography>
            </Box>
          </Box>
          {loadingService ? (
            <Box className={classes.boxLoadingIcon}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isFutureDate ? (<FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
                error={typeof (errorMessage) === 'string'}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecione o serviço
                </InputLabel >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Selecione o serviço"
                  error={typeof (errorMessage) === 'string'}
                  onChange={() => {
                    setErrorMessage(undefined);
                    setErrorAlert(false);
                  }}
                  value={serviceSelected?.id ?? ''}
                  classes={{
                    select: classes.menuItem,
                  }}
                >
                  {service_data?.results?.map((service) => (
                    <MenuItem
                      onClick={() => {
                        console.log(service);

                        setServiceSelected(service);
                        handleCheckHours(service.id, unidade_slug);
                      }}
                      value={service.id}
                      key={service.slug}
                    >
                      {service.titulo}
                    </MenuItem>
                  ))}
                </Select>
                {typeof (errorMessage) === 'string' && (
                  <FormHelperText>{errorMessage}</FormHelperText>
                )}
              </FormControl>) : (
                <>
                  <AsyncSelect
                    name="scheduling"
                    onChange={handleSetService}
                    placeholder="Procurar serviço"
                    value={searchService}
                    loadOptions={handleSearchService}
                    noDataMessage="Nenhum serviço encontrado"
                    validate={validateQueryService}
                  />

                </>
              )}
            </>
          )}
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControl}
            error={typeof (cpfValidationMessage) === 'string'}
          >
            <TextField
              value={cpf}
              onChange={(e) => {


                setCpf(cpfMask(e.target.value));
                setCpfValidationMessage(undefined);
                checkCpf(e?.target?.value || '');

              }}
              variant="outlined"
              label="CPF do cidadão"
              error={typeof (cpfValidationMessage) === 'string'}
            />
            {typeof (cpfValidationMessage) === 'string' && (
              <FormHelperText>{cpfValidationMessage}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            classes={{ root: classes.root }}
            variant="outlined"
            className={classes.formControl}
            error={typeof (nomeValidationMessage) === 'string'}
          >
            <TextField
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
                setNomeValidationMessage(undefined);
              }}
              variant="outlined"
              label="Nome do cidadão"
              disabled={
                typeof (errorMessage) === 'string'

              }

              error={typeof (nomeValidationMessage) === 'string'}
            />
            {typeof (nomeValidationMessage) === 'string' && (
              <FormHelperText>{nomeValidationMessage}</FormHelperText>
            )}
          </FormControl>
          {errorAlert && (
            <FormHelperText className={classes.textError}>
              Usuário já possuí agendamento para este serviço nessa unidade.
            </FormHelperText>
          )}

          {isFutureDate && (
            <FormControl
              classes={{ root: classes.root }}
              variant="outlined"
              className={classes.formControl}
              required
              error={(searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0)) || typeof (errorMessage) === 'string'}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Selecione o local
              </InputLabel >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Selecione o local"
                error={(searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0))}
                disabled={!(searchService?.data?.servicosUnidade?.length > 0)}
                onChange={(event): void => {
                  setUnitySelected(event.target.value as string);
                }}
                classes={{
                  select: classes.menuItem,
                }}
                value={unitySelected ?? ''}
              >
                {searchService?.data?.servicosUnidade?.map((unity: any) => (
                  <MenuItem
                    value={unity.unidade.slug_unidade}
                    key={unity.id}
                  >
                    {unity.unidade.nome}
                  </MenuItem>
                ))}
              </Select>
              {((searchService?.data && !(searchService?.data?.servicosUnidade?.length > 0)) || errorMessage) && (
                <FormHelperText>{`${errorMessage ? errorMessage : 'Nenhum local de atendimento disponível para este serviço.'}`} </FormHelperText>
              )}
            </FormControl>
          )}


          {isFutureDate && searchService?.data && unitySelected && (
            <>
              <div className={classes.separateLine} />
              <FormControl
                classes={{ root: classes.root }}
                disabled={!unitySelected}
                variant="outlined"
                className={classes.formControl}
              >
                <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture={false}
                    minDateMessage="Você não pode agendar em dias anteriores"
                    invalidDateMessage="A data informada é inválida"
                    minDate={dateTomorrow}
                    InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                    autoOk
                    disabled={!unitySelected}
                    variant="inline"
                    required
                    inputVariant="outlined"
                    label="Selecionar Data"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputAdornmentProps={{ position: 'end', color: '#000' }}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        setSelectedDate(date);
                        if (unitySelected && searchService?.data) {
                          handleCheckHours(searchService.data.id, unitySelected, date);
                        }
                      }
                    }}
                  />
                </MuiPickersUtilsProvider>

              </FormControl>
              <FormControl
                classes={{ root: classes.root }}
                variant="outlined"
                className={classes.formControl}
                required
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Selecionar Horário
                </InputLabel >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Selecionar Horário"
                  disabled={!(hoursList.length > 0)}
                  onChange={() => {
                    setErrorMessage(undefined);
                    setErrorAlert(false);
                  }}
                  value={hourSelected}
                  classes={{
                    select: classes.menuItem,
                  }}
                >
                  {hoursList?.map((hour) => (
                    <MenuItem
                      onClick={() => {
                        setHourSelected(hour);
                      }}
                      value={hour}
                      key={hour}
                    >
                      {hour}
                    </MenuItem>
                  ))}
                </Select>

              </FormControl>
            </>
          )}


          <div className={classes.buttonCloseModal}>
            {!loading && (
              <Link className={classes.link} onClick={handleClose}>
                fechar
              </Link>
            )}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSchedulerOrganic}
              disabled={
                loading ||
                !nome ||
                (!isFutureDate ? !serviceSelected : false) ||
                (isFutureDate ? (!searchService || !unitySelected || !selectedDate || !hourSelected) : false)
              }
            >
              {!loading ? "Gerar" : <CircularProgress />}
            </Button>
          </div>
        </DialogContent>
      </div>
      {schedulerData && (
        <div style={{ display: IsSavedScheduler ? 'initial' : 'none' }}>
          <DialogTitle className={classes.headerModal}>
            <Typography className={classes.titleModal}>
              Atendimento Gerado com Sucesso!
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.contentModal}>
            <MdCheckCircleOutline
              className={classes.icon}
            />
            <Box className={classes.boxInfo}>
              <Box className={classes.boxData}>
                <Typography className={classes.titleBolder}>{schedulerData?.nome}</Typography>
                <Typography className={classes.cpfText}>{cpfMask(schedulerData?.cpf)}</Typography>
              </Box>
              <Box className={classes.boxService}>
                <Typography className={classes.titleService}>Serviço</Typography>
                <Typography className={classes.titleBolder}>{serviceSelected?.titulo}</Typography>
              </Box>
              {JSON.parse(localStorage.getItem('gov_ticket_office') || '{}')?.unidade?.nome && <Box className={classes.boxService}>
                <Typography className={classes.titleService}>Local de atendimento</Typography>
                <Typography className={classes.titleBolder}>{JSON.parse(localStorage.getItem('gov_ticket_office') || '{}')?.unidade?.nome}</Typography>
              </Box>}

              {schedulerData?.protocolo && <Box className={classes.boxService}>
                <Typography className={classes.titleService}>Protocolo agendamento</Typography>
                <Typography className={classes.titleBolder}>{schedulerData?.protocolo}</Typography>
              </Box>}

            </Box>
            <LinkR
              className={classes.linkTo}
              to={`/imprimir_agendamento/?cod=${schedulerData?.ticket}&nome=${schedulerData?.nome}&cpf=${schedulerData?.cpf}&data=${schedulerData.data}&hora=${schedulerData?.hora}&emissao=${schedulerData?.updated_at}&protocolo=${schedulerData.protocolo}`}
              target="_blank"
            >
              Imprimindo comprovante...
            </LinkR>
          </DialogContent>
          <DialogActions className={classes.footerModal}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => history.go(0)}
              className={classes.buttonClose}
            >
              Fechar
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}

export default memo(DialogNotAttend);