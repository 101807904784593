import { AxiosResponse } from "axios";
import { apisiseci } from "./api";


interface ParamsServices {
  page: number;
  ativo: boolean;
  publico_especifico: string;
  categoria_slug?: string;
  search?: string;
  agendavel: boolean;
}
export const getInterService = (token: string, id: number): Promise<AxiosResponse> =>
  apisiseci.get(`/cms/serv_internos/?servico=${id}`, {
    headers: {
      Authorization: `${token}`,
    },
  });

export const getUnidadeServices = (
  slug_unidade: string,
  page?: number
): Promise<AxiosResponse> => {
  let url = '';
  if (page) {
    url = `/cms/servicos_unidades/?unidade_slug=${slug_unidade}&page=${page}`;
  } else {
    url = `/cms/servicos_unidades/?unidade_slug=${slug_unidade}`;
  }

  return apisiseci.get(url, {
    headers: {
      Authorization: (window as any)._env_.REACT_APP_API_SISECI_KEY,
    }
  })
}


export const getService = (slug: string): Promise<AxiosResponse> => apisiseci
  .get(`/cms/servicos/?slug=${slug}`);

export const getServiceWithParams = (params: ParamsServices, token: string): Promise<AxiosResponse> => apisiseci
  .get('/cms/servicos_busca/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

